<template>
  <div>
    <div id="oomPage">
      <b-container class="topSec">
        <b-row>
          <b-col class="titleDescript">
            <b-row
              class="topSecrow title"
            >
              <b-col>
                <h3>
                  Details
                </h3>
              </b-col>
              <b-col>
                <b-img
                  class="logo"
                  :src="'https://assets.asiantour.com/asian-tour/2023/09/AT-logo-1024.png'"
                ></b-img>
              </b-col>
            </b-row>
            <b-row class="topSecrow titleDate">
              <b-col>
                {{ currentOom.full_name }}
              </b-col>
            </b-row>
            <b-row class="topSecrow titleDate smallText">
              <b-col>
                {{ currentOom.last_tournament }}
              </b-col>
            </b-row>
            <b-row class="topSecrow">
              <b-col lg="4">
                <b-dropdown
                  id="dropdown-1"
                  :text="seasonDes(id)"
                  class="m-md-2"
                >
                  <b-dropdown-item
                    v-on:click="changeYear"
                    :title="years.code"
                    v-for="(years,
                    index) in tm_params.seasons.seasons_entry.slice().reverse()"
                    :key="index"
                    >{{ years.desc }}</b-dropdown-item
                  >
                </b-dropdown>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
      <b-container>
        <b-row>
          <b-col cols="12">
            <span class="affiliate" v-if="previousOom.affiliate === 'Y' || currentOom.affiliate === 'Y'">
            * Indicates affiliate member
            </span>
          </b-col>
        </b-row>
      </b-container>
      <b-container>
        <table
          class="table table-striped table-bordered"
        >
          <thead
          >
            <tr>
              <template v-if="isMobile(true)">
                <th>Pos</th>
              </template>
              <template v-else>
                <th>Position</th>
              </template>
              <th style="text-align: left">Name</th>
              <th>Nat</th>
              <template v-if="currentOom.oom_type == 'cash'">
                <th class="hidemob" style="text-transform: capitalize;">Prize Money</th>
              </template>
              <template v-else>
                <th class="hidemob" style="text-transform: capitalize;">{{currentOom.oom_type}}</th>
              </template>
              <th>Played</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(oom, index) in currentOom.oom.oom_entry" :key="index">
              <td>{{ oom.pos }}</td>
              <td style="text-align: left">
                <template v-if="oom.profile == 'Y'">
                  <a :href="'/playerprofile/' + oom.playerno">
                    {{ oom.name }}
                  </a>
                </template>
                <template v-else>
                  {{ oom.name }}
                </template>
                <span v-if="oom.sponsored === 'Y'">
                  <img :src="config.VUE_APP_TITLEIST" class="titliestMob" />
                </span>
              </td>
              <td>
                <img
                  :class="flag"
                  :src="(config.VUE_APP_FLAG_URL + oom.nationality) | lowercase"
                />
              </td>
              <td class="hidemob">{{ oom.oom_value }}</td>
              <td>{{ oom.played }}</td>
            </tr>
          </tbody>
        </table>
      </b-container>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "oom",
  props: ["Year", "home", "develop", "oom", "config", "season", "seasonsadt"],
  data() {
    return {
      fields: [
        { key: "pos", label: "Position" },
        { key: "name", label: "Name", class: "playerName" },
        { key: "nationality", label: "Nat", class: "playerNat" },
        { key: "oom_value", label: "USD" },
        { key: "tournaments_played", label: "Played" }
      ],
      hasOoms: [],
      currentOom: [],
      previousOom: [],
      data: [],
      tm_params: [],
      year: parseInt(this.season) - 1,
      flag: "flag",
      id: [],
    };
  },
  filters: {
    lowercase: function(value) {
      if (!value) {
        return "";
      }
      return value.toLowerCase() + ".svg";
    }
  },
  methods: {
    isMobile() {
      if( screen.width <= 500 ) {
        return true;
      }
      else {
        return false;
      }
    },
    // seasonDes(id){
    //   var year = this.tm_params.seasons.seasons_entry.filter((seas) => seas.code === id)
    //   return year[0].desc
    // },
    changeYear: function(event) {
      var id = event.target.getAttribute("title");
      if (id === '2011') {
        console.log('2011 True')
        return (
          (this.id = id),
          axios
            .get(
              process.env.VUE_APP_TIC_BASE +
                this.develop +
                "/" +
                this.id +
                "/" +
                this.id +
                "-ooms-oom-" +
                'OX' +
                ".json?randomadd=" +
                new Date().getTime()
            )
            .then(response => {
              this.currentOom = response.data;
              this.oom_type = response.data.oom_type;
              this.previousOom = response.data;
              console.log("this.currentOom --- 153");
              console.log(this.currentOom);
            })
        );
      } else {
        console.log('2011 False')
        return (
          (this.id = id),
          axios
            .get(
              process.env.VUE_APP_TIC_BASE +
                this.develop +
                "/" +
                this.id +
                "/" +
                this.id +
                "-ooms-oom-" +
                this.oom +
                ".json?randomadd=" +
                new Date().getTime()
            )
            .then(response => {
              this.currentOom = response.data;
              this.previousOom = response.data;
              this.oom_type = response.data.oom_type;
              console.log("this.currentOom --- 153");
              console.log(this.currentOom);
            })
        );
      }
    },
    CurrentSeason: function() {
      if (this.develop == 'adt') {
        axios
          .get(
            process.env.VUE_APP_TIC_BASE +
              'adt' +
              "/" +
              this.seasonsadt +
              "/" +
              this.seasonsadt +
              "-ooms-oom-" +
              this.oom +
              ".json?randomadd=" +
              new Date().getTime()
          )
        .then(response => {
          this.currentOom = response.data;
          this.oom_type = response.data.oom_type;
        })
      } else {
        axios
          .get(
            process.env.VUE_APP_TIC_BASE +
              'apga' +
              "/" +
              this.season +
              "/" +
              this.season +
              "-ooms-oom-" +
              this.oom +
              ".json?randomadd=" +
              new Date().getTime()
          )
        .then(response => {
          this.currentOom = response.data;
          this.oom_type = response.data.oom_type;
        })
      }
    },
    PastSeason: function() {
      if (this.develop == 'adt') {
        axios
          .get(
            process.env.VUE_APP_TIC_BASE +
              'adt' +
              "/" +
              this.year +
              "/" +
              this.year +
              "-ooms-oom-" +
              this.oom +
              ".json?randomadd=" +
              new Date().getTime()
          )
        .then(response => {
          this.currentOom = response.data;
          this.oom_type = response.data.oom_type;
        })
      } else {
        axios
          .get(
            process.env.VUE_APP_TIC_BASE +
              'apga' +
              "/" +
              this.year +
              "/" +
              this.year +
              "-ooms-oom-" +
              this.oom +
              ".json?randomadd=" +
              new Date().getTime()
          )
        .then(response => {
          this.currentOom = response.data;
          this.oom_type = response.data.oom_type;
        })
      }
    },

  },
  mounted() {
    // this.CurrentSeason()
    setTimeout(() => {
      axios 
        .get(
          process.env.VUE_APP_TIC_BASE +
            this.develop +
            "/tmticx?randomadd=" +
            new Date().getTime()
        )
        .then(response => {
          this.tm_params = response.data;
          return axios.get(
            process.env.VUE_APP_TIC_BASE +
            this.develop +
            "/" +
            this.season +
            "/tmticx?~ooms=Y~randomadd=" +
            new Date().getTime()
          );
        })
        .then(response => {
          this.tm_params = response.data,
          this.id = response.data.tm_params.season_code;
          this.hasOoms = response.data.ooms.ooms_entry;
          if (this.hasOoms == undefined) {
            console.log('OOM Undefined')
            this.PastSeason()
          } else {
            console.log('OOM NOT Undefined')
            this.CurrentSeason()
          }
        }); 
    }, 1000);
  }
};
</script>

<style scoped>
::v-deep a.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529!important;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
::v-deep a.dropdown-item {
  color: #212529!important;
}
::v-deep span.affiliate {
  font-size: 14px;
}
.smallText {
  font-size: 13px!important;
  margin-bottom: 15px;
}
.table-bordered {
  border: 0px solid #dee2e6;
}
::v-deep .table-bordered th,
.table-bordered td {
  border: none;
}
tr > td {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  width: 20%;
  text-align: center;
  padding: 5px;
  font-size: 14px;
  padding-top: 10px !important;
  padding-bottom: 12px !important;
}
thead.developGreenNew {
  background: #76c3bd;
}
::v-deep th {
  width: 5% !important;
  text-align: center;
  border: none;
  padding: 9px;
  font-size: 14px;
}
.titleDescript {
  padding-left: 29px;
}
div#dropdown-1 {
  width: 100%;
  padding-left: 0;
  margin-left: 0 !important;
}
::v-deep .btn {
  background-color: #fff;
  border-radius: 0px;
  border-color: transparent;
  color: #aaa !important;
}
.titleDate {
  /* text-transform: capitalize; */
  font-weight: bolder;
  font-size: 19px;
  color: #133f7a;
  font-weight: 500;
}
::v-deep th.playerName > div {
  text-align: left;
}
::v-deep td.playerName {
  text-align: left;
}
.playerName {
  text-align: left;
}
::v-deep thead {
  color: white;
  background-color: #133f7b;
  border-radius: 10px;
  font-size: 14px;
}

::v-deep .developGreen > thead {
  background-color: #76c3bd !important;
}
::v-deep .developGreen > tfoot {
  background-color: #76c3bd !important;
}
.developGreen {
  color: #76c3bd !important;
}
::v-deep .developGreen > tbody > tr > td {
  color: black !important;
}

::v-deep .table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 0;
}

::v-deep tfoot {
  background-color: #133f7b;
  color: white;
  border: none;
}

::v-deep th {
  width: 15%;
  text-align: center;
  border: none;
}
::v-deep th:first-child {
  /* border-top-left-radius: 15px; */
}
::v-deep th:last-child {
  /* border-top-right-radius: 15px; */
}
::v-deep tfoot > td:first-child {
  border-bottom-left-radius: 15px;
}
::v-deep tfoot > td:last-child {
  border-bottom-right-radius: 15px;
}
::v-deep td {
  text-align: center;
  /* padding: 0; */
  padding-top: 5px;
  padding-bottom: 5px;
}
.listing > a {
  color: white;
}

.flag {
  width: 30px;
  border: 0.25px solid #e2e1e1
}
::v-deep tbody > tr:nth-child(even) {
  background-color: #f9f9f9;
}
::v-deep tbody > tr:nth-child(odd) {
  background-color: white;
}
.slider {
  padding-top: 5%;
  padding-bottom: 5%;
}

#oomPage {
  background-color: #f1f1f1;
  padding-bottom: 50px;
}
.topSec {
  padding-top: 5%;
  padding-bottom: 5%;
}

.title {
  color: #0b3f7e;
}

.topSecrow {
  /* padding-top: 2%; */
}
.logo {
  width: 120px;
  float: right;
}
table {
  margin-bottom: 0;
}
@media only screen and (max-width: 500px) {
  ::v-deep thead > tr {
    height: auto!important;
  } 
  ::v-deep .table th, .table td {
    padding: 0.75rem;
    vertical-align: middle;
    border-top: 0;
  }
  ::v-deep tr:nth-child(odd) {
    height: 65px;
  }
  ::v-deep tr:nth-child(even) {
    height: 65px;
  }
  ::v-deep .titleDescript {
    padding-left: 15px;
  }
}
@media only screen and (max-width: 480px) {
    th.hidemob {
        display: none;
    }
    td.hidemob {
        display: none;
    }
    ::v-deep th {
        padding: 0.5rem !important;
    }
}
</style>
